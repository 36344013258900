.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 0px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-projects-project {
	width: 100%;
	padding: 10px;

}

@media (max-width: 600px) {
	.all-projects-project {
		width: 100%;
		margin-bottom: 30px;
	}
}
